import { Footer, Navigation, PreviewBanner, Section } from "components";
import type { PageQuery } from "generated";
import { PageDocument, PageSlugsDocument } from "generated";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";
import Head from "next/head";
import { request } from "utilities";

export interface PageProps extends PageQuery {
  preview: boolean;
}

export const getStaticPaths: GetStaticPaths = async () => {
  const result = await request(PageSlugsDocument, {}, {});

  return {
    paths: result.allPages.map((page) => `/${page.slug}`),
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<
  PageProps,
  { slug: string }
> = async ({ params, preview }) => {
  if (!params) {
    throw "No parameters found.";
  }

  const result = await request(
    PageDocument,
    { slug: params.slug },
    { includeDrafts: preview }
  );

  return {
    props: { ...result, preview: !!preview },
  };
};

const Page: NextPage<PageProps> = ({ navigation, footer, page, preview }) => {
  if (!page || !navigation || !footer) {
    throw "Page, navigation, or footer data not found.";
  }

  return (
    <>
      <Head>
        <title>{`${page.name} - JCore`}</title>
        <meta name="description" content={page.description} />
        <meta property="og:image" content={page.image.url} />
      </Head>
      <PreviewBanner preview={preview} />
      <Navigation {...navigation} />
      {page.blocks.map((block) => (
        <Section key={block.id} {...block} />
      ))}
      <Footer {...footer} />
    </>
  );
};

export default Page;
